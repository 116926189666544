import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import moment from "./libs/moment";

import CreateView from "./views/CreateView";
import HomeView from "./views/HomeView";
import PaymentsView from "./views/PaymentsView";
import JoinView from "./views/JoinView";
import PrivacyView from "./views/PrivacyView";

import Emoji from "./components/Emoji";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <Router>
      <Container id="wrapper">
        <Navbar>
          <LinkContainer to={"/"}>
            <Navbar.Brand>
              <strong>Izzie</strong> - Le bot pour ta coloc
            </Navbar.Brand>
          </LinkContainer>

          <Nav className="ml-auto">
            <LinkContainer to={"/create"}>
              <Button variant="primary" className="d-none d-sm-block">
                Créer coloc
              </Button>
            </LinkContainer>

            <LinkContainer to={"/create"}>
              <Button variant="primary" className="d-block d-sm-none">
                Créer
              </Button>
            </LinkContainer>
          </Nav>
        </Navbar>

        <Switch className="pageContainer">
          <Route path="/create" component={CreateView} />
          <Route path="/payments/:colocId" component={PaymentsView} />
          <Route path="/join/:colocId?" component={JoinView} />
          <Route path="/privacy" component={PrivacyView} />

          <Route path="/" component={HomeView} />
        </Switch>
      </Container>

      <footer>
        <p>
          Fait avec <Emoji symbol="❤️" /> à Bruxelles <Emoji symbol="🇧🇪" />
        </p>
      </footer>
    </Router>
  );
}

export default App;
