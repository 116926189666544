import app from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const config = {
  apiKey: "AIzaSyCdAsbKmDE3DcMoz6t4Af2HELouvw0pzUE",
  authDomain: "colocbot-6cad0.firebaseapp.com",
  databaseURL: "https://colocbot-6cad0.firebaseio.com",
  projectId: "colocbot-6cad0",
  storageBucket: "colocbot-6cad0.appspot.com",
  messagingSenderId: "228077069051",
  appId: "1:228077069051:web:110c4961425afcdb22065e",
  measurementId: "G-ZX0ZP28CK0"
};

export default class Firebase {
  constructor() {
    app.initializeApp(config);

//    app.firestore.setLogLevel("debug");    // "debug" | "error" | "silent"

    this.auth = app.auth();
    this.db = app.firestore();
    this.fct = app.functions();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
  doSignOut = () => this.auth.signOut();
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);
}
