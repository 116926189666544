import React from "react";
import { Field } from 'formik';

import Form from "react-bootstrap/Form";
import "./FormField.css";


const Control = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <>
      <Form.Control {...field} isInvalid={(touched[field.name] && errors[field.name])} {...props} />
      {/*<Form.Control.Feedback type="invalid">{errors[field.name]}</Form.Control.Feedback>*/}
    </>
  );
};


export default props => <Field component={Control} {...props} />;
