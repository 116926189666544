import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Card, ListGroup, Row, Col, Button, Spinner } from "react-bootstrap";
import { withFirebase } from "../../services/Firebase";
import Emoji from '../../components/Emoji';


import "./JoinView.css";

class JoinView extends Component {
  state = {
    renters: [{ name: "" }],
    coloc: {},
    connectedAs: undefined,
    botConfigured: false,
    error: null,
    loading: false,
  };

  componentDidMount() {
      this._getColoc().catch((e) => { 
        this.setState({ error: e.message, loading: false });
      });
  }

  _joinAs = user => async () => {
    const {
      firebase: { auth, fct },
    } = this.props;

    this.setState({ isJoining: true });

    const loginRenter = fct.httpsCallable("loginRenter");
    try {
      const token = await loginRenter({ id: user.id });
      auth.signInWithCustomToken(token.data).then(() => {
        const userLoggedIn = auth.currentUser;
        userLoggedIn.updateProfile({
          displayName: user.name,
        });
      });
    } catch (e) {
      console.warn(e);
    }

    return this.setState({ connectedAs: user, isJoining: false });
  };

  _getColoc = async () => {
    const {
      firebase: { db },
      match: { params }
    } = this.props;
    
    this.setState({ loading: true });

    let { master } = this.state;

    if (!params.colocId) {
      return this.setState({ error: "noId" });
    }

    const coloc = await db
      .collection("coloc")
      .doc(params.colocId)
      .get();

    if (!coloc.exists) {
      return this.setState({ error: "noId" });
    }

    const colocData = coloc.data();
    await db
      .collection("renter")
      .where("coloc", "==", coloc.id)
      .onSnapshot(rentersQuery => {
        let { connectedAs } = this.state;

        const renters = [];
        for (let renter of rentersQuery.docs) {
          renters.push({
            ...renter.data(),
            id: renter.id
          });

          if (renter.id === colocData.master) {
            master = renter.data();
          }

          if (connectedAs && renter.id === connectedAs.id) {
            this.setState({ connectedAs: { id: renter.id, ...renter.data() } });
          }
        }

        return this.setState({
          renters,
          master
        });
      });

    return this.setState({
      coloc: {
        id: coloc.id,
        ...colocData
      },
      loading: false,
    });
  };

  _open = url => () => {
    window.open(url, "_blank");
  };

  render() {
    const { renters, coloc, connectedAs, master, error, loading, isJoining } = this.state;

    if (!error && loading) {
      return (
        <div className="loading">
          <Row className="align-items-center">
            <Col>
              <div className="spinner-grow" role="status" />
              <div>Chargement des membres de la coloc...</div>
            </Col>
          </Row>
        </div>
      );
    }

    
    if (error && error === "noId") {
      return (
        <Row>
          <Col>
            <h2>Lien invalide <Emoji symbol="🤭"/></h2>
            <p>
              Ce lien ne semble pas valide. Es-tu certain(e) d'avoir bien copié
              le lien de la coloc ?
            </p>
            <p>
              Si tu as perdu le lien dédié à ta coloc, demande le à
              l'administrateur de ta coloc. Sinon, demande le à notre bot, il
              saura sûrement t'aider.
            </p>
            <p>
              Si ta coloc n'est pas encore sur Izzie, n'hésite pas à
              l'ajouter, c'est par ici:
            </p>

            <LinkContainer to={"/create"}>
              <Button variant="primary">Créer une nouvelle coloc</Button>
            </LinkContainer>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        {!connectedAs ? (
          <Col>
            <h2>{`Rejoins ${coloc.name} 🏠`}</h2>
            <p>
              {master ? master.name : "On"} t'invite à rejoindre Izzie pour
              les paiements des loyers de la coloc. Pour commencer, qui es-tu ?
            </p>
            <Card>
              <Card.Header>{`Rejoindre ${coloc.name} en tant que:`}</Card.Header>
              {isJoining ? (<p>
                <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              </p>) :
                (<ListGroup variant="flush">
                  {!!renters &&
                    renters.map(renter => (
                      <ListGroup.Item
                        key={renter.id}
                        action
                        onClick={this._joinAs(renter)}
                      >
                        {renter.name}
                      </ListGroup.Item>
                    ))}
                </ListGroup>)
              }
            </Card>
          </Col>
        ) : (
          <Col>
            <h2>{`Rejoins ${coloc.name} 🏠`}</h2>
            <p>
              Salut {connectedAs.name}, maintenant que tu es connecté, il ne
              reste qu'une étape.
            </p>
            <Row>
              <Col>
                Pour recevoir les rappels, tu dois au moins envoyer un message
                au bot. Clique simplement ici:
                <ListGroup>
                  <ListGroup.Item
                    action
                    onClick={this._open(
                      `https://m.me/101216431343927?ref=${connectedAs.id}`
                    )}
                  >
                    Messenger {connectedAs.messenger ? "(Configuré ✔)" : ""}
                  </ListGroup.Item>
                  
                  {/*
                  <ListGroup.Item action disabled>
                    Notification (Bientôt)
                  </ListGroup.Item>
                  <ListGroup.Item action disabled>
                    SMS (Bientôt)
                  </ListGroup.Item>*/}
                </ListGroup>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    );
  }
}

export default withFirebase(JoinView);
