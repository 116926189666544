import React, { Component } from "react";
import "./Form.css";
import { Button, Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";

export default class FormCards extends Component {
  state = {
    card: 0
  };

  _goTo = inc => () => {
    const { card } = this.state;
    const { children, errors } = this.props;

    const names = children[card].props.names;
    if (errors && names) {
      for (let n of names) if (errors[n]) return false;
    }

    this.setState({
      card: Math.min(children.length - 1, Math.max(0, card + inc))
    });
  };

  _isMobile = () => {
    return window.innerWidth <= 600;
  };

  _isDisabled(card, children, errors) {
    const child = children[card];
    if (!child) return true;
    if (card >= children.length - 1) return true;
    if (!child.props || !child.props.names) return false;

    for (let name of child.props.names) {
      if (errors[name]) return true;
    }

    return false;
  }

  render() {
    if (this._isMobile()) return this.renderMobile();
    return this.renderDesktop();
  }

  renderMobile() {
    const { children, ...props } = this.props;
    const { card } = this.state;

    return (
      <Formik {...props}>
        {({ isSubmitting, errors }) => (
          <Form className={["form", "mobile"]}>
            <h3 className="steps">
              Etape {card + 1} sur {children.length-1}
            </h3>

            {children[Math.min(card, children.length - 1)]}

            <Row className="buttonBar">
              <Col>
                <Button onClick={this._goTo(-1)} disabled={card <= 0}>
                  Précédent
                </Button>
              </Col>
              <Col xs="auto"></Col>
              <Col>
                {card === children.length - 2 ? (
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={this._isDisabled(card, children, errors)}
                  >
                    Créer la coloc !
                  </Button>
                ) : (
                  <Button
                    onClick={this._goTo(1)}
                    disabled={this._isDisabled(card, children, errors)}
                  >
                    Suivant
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }

  renderDesktop() {
    const { children, ...props } = this.props;

    return (
      <Formik {...props}>
        {({ isSubmitting }) => (
          <Form className={["form", "desktop"]}>{children}</Form>
        )}
      </Formik>
    );
  }
}
