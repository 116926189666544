import React, { Component } from "react";
import { Form, Button, Row, Col, InputGroup, Spinner } from "react-bootstrap";

import * as yup from "yup";

import FormCard from "../../components/FormCard";
import FormField from "../../components/FormField";
import FormCards from "../../components/FormCards";
import Emoji from "../../components/Emoji";
import ShareModal from "../../components/ShareModal";

import { withFirebase } from "../../services/Firebase";

import "./CreateView.css";


const formScheme = yup.object({
  name: yup
    .string()
    .min(5)
    .required(),
  rent: yup
    .number()
    .positive()
    .required(),
  dayRent: yup
    .number()
    .integer()
    .min(1)
    .max(31)
    .required(),
  masterAccount: yup
    .string()
    .required(),
  dayReminder: yup
    .number()
    .integer()
    .min(1)
    .max(31)
    .required(),
  charges: yup.object({
    elec: yup
      .number()
      .positive()
      .required(),
    internet: yup
      .number()
      .positive()
      .required(),
    water: yup
      .number()
      .positive()
      .required(),
    other: yup
      .number()
      .positive()
      .required()
  }),
  colocs: yup.array().of(
    yup.object({
      name: yup.string().required(),
      multiple: yup.number()
    })
  )
});

const roundPrice = x => Math.round(x * 100) / 100;

class CreateView extends Component {
  state = {
    colocs: [{ name: "", multiple: 1 }],
    charges: { internet: 0, water: 0, elec: 0, other: 0 },
    rent: 0,
    dayRent: 1,
    dayReminder: 1,
    name: "",
    masterAccount: "",
    masterIndex: 0,
    colocLink: undefined,
    error: null,
  };

  async componentDidMount() {
    const {
      firebase: { auth }
    } = this.props;

    await auth.signInAnonymously();
  }

  _addColoc = () => {
    const { colocs } = this.state;
    colocs.push({ name: "", multiple: 1 });
    return this.setState({ colocs });
  };

  _removeColoc = index => () => {
    const { colocs } = this.state;
    colocs.splice(index, 1);
    return this.setState({ colocs });
  };

  _onSubmit = async form => {
    try {
      const {
        firebase: { db }
      } = this.props;
      const {
        name,
        rent,
        dayRent,
        charges,
        colocs,
        masterAccount,
        dayReminder
      } = form;

      this.setState({ isSubmitting: true });

      const { masterIndex } = this.state;

      const chargeTotal = Object.keys(charges).reduce(
        (a, k) => a + parseFloat(charges[k] || 0),
        0
      );

      const coloc = {
        name,
        rent,
        dayRent,
        dayReminder,
        account: masterAccount,
        chargeTotal
      };

      const fbColoc = await db.collection("coloc").add(coloc);

      var batch = db.batch();
      var rentersRef = db.collection("renter");
      var chargesRef = db.collection("charge");

      colocs.map(({ name, multiple }, index) => {
        const renterRef = rentersRef.doc();
        const isMaster = index === parseInt(masterIndex, 10);

        batch.set(renterRef, {
          name,
          master: isMaster,
          account: isMaster ? masterAccount : "",
          multiple: parseInt(multiple, 10),
          coloc: fbColoc.id
        });

        if (isMaster)
          return batch.set(fbColoc, { master: renterRef.id }, { merge: true });
        return undefined;
      });
      Object.keys(charges).map(k =>
        batch.set(chargesRef.doc(), {
          type: k,
          price: parseFloat(charges[k]),
          coloc: fbColoc.id
        })
      );

      await batch.commit();

      this._displayShare(fbColoc.id);
    } catch (e) {
      this.setState({ error: e.message });
    }

    this.setState({ isSubmitting: false });

    return false;
  };

  _displayShare = id => {
    this.setState({ colocLink: `/join/${id}` });
  };

  _handleChange = event => {
    const target = event.target;

    if (target.name.indexOf(".") > -1) {
      // Object
      const split = target.name.split(".");

      if (split[0].indexOf("[") > -1) {
        // Array of objects ?
        const base = split[0].split("[")[0];
        const str = split[0].split("[")[1];
        const i = str.substring(0, str.length - 1);
        const array = this.state[base];
        array[i][split[1]] = target.value;
        return this.setState({ [base]: array });
      }

      const base = split[0];
      const array = this.state[base];
      array[split[1]] = target.value;
      return this.setState({ [base]: array });
    }
    this.setState({ [target.name]: target.value });
  };

  render() {
    const { colocs, rent: rentStr, charges, colocLink, isSubmitting } = this.state;
    const rent = parseFloat(rentStr) || 0;

    const multipleTotal = colocs.reduce(
      (a, v) => a + (parseInt(v.multiple, 10) || 0),
      0
    );
    const chargeTotal = roundPrice(
      Object.keys(charges).reduce((a, k) => a + parseFloat(charges[k] || 0), 0)
    );

    const colocsDefaultValues = new Array(colocs.length).fill({
      name: "",
      multiple: 1
    });
    return (
      <>
        <ShareModal link={colocLink} />
        <FormCards
          initialValues={{
            name: "",
            rent: "",
            dayRent: "",
            masterAccount: "",
            dayReminder: "",
            masterIndex: 0,
            charges: {
              elec: "",
              internet: "",
              water: "",
              other: ""
            },
            colocs: colocsDefaultValues
          }}
          onSubmit={this._onSubmit}
          validationSchema={formScheme}
        >
          <FormCard names={["name"]}>
            <h2>
              Nom de ta coloc <Emoji symbol="🏠" />
            </h2>
            <p>Définis un nom à ta coloc, c'est plus fun !</p>
            <Form.Group as={Row} controlId="formName">
              <Form.Label column sm="4">
                Nom de coloc
              </Form.Label>
              <Col sm="8">
                <FormField placeholder="ex: Le QG" name="name" autoFocus />
              </Col>
            </Form.Group>
          </FormCard>

          <FormCard names={["rent", "dayRent"]}>
            <h2>
              Loyer mensuel <Emoji symbol="💰" />
            </h2>
            <p>
              Quel est le loyer total par mois de toute la coloc ? Et quel jour
              du mois devez-vous payer au propriétaire ?
            </p>
            <Form.Group as={Row} controlId="formRent">
              <Form.Label column sm="4">
                Loyer mensuel total
              </Form.Label>
              <Col sm="8">
                <InputGroup>
                  <FormField
                    placeholder="ex: 2000"
                    name="rent"
                    type="number"
                    min="0"
                    step="0.01"
                    onBlur={this._handleChange}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>€ / mois</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formRent">
              <Form.Label column sm="4">
                A payer avant le X du mois
              </Form.Label>
              <Col sm="8">
                <InputGroup>
                  <FormField
                    placeholder="ex: 1"
                    type="number"
                    min="1"
                    max="31"
                    name="dayRent"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>jour</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Group>
          </FormCard>

          <FormCard names={["charges"]}>
            <h2>
              Charges <Emoji symbol="💧" />
            </h2>
            <p>
              On passe aux charges, quel est le montant mensuel de charges total
              ?
            </p>
            <Form.Group as={Row} controlId="formRent">
              <Form.Label column xs="4">
                Internet
              </Form.Label>
              <Col xs="8">
                <InputGroup>
                  <FormField
                    placeholder="ex: 50"
                    type="number"
                    min="0"
                    step="0.01"
                    name="charges.internet"
                    onBlur={this._handleChange}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>€/mois</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formRent">
              <Form.Label column xs="4">
                Eau
              </Form.Label>
              <Col xs="8">
                <InputGroup>
                  <FormField
                    placeholder="ex: 50"
                    type="number"
                    min="0"
                    step="0.01"
                    name="charges.water"
                    onBlur={this._handleChange}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>€/mois</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formRent">
              <Form.Label column xs="4">
                Gaz & Elec
              </Form.Label>
              <Col xs="8">
                <InputGroup>
                  <FormField
                    placeholder="ex: 50"
                    type="number"
                    min="0"
                    step="0.01"
                    name="charges.elec"
                    onBlur={this._handleChange}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>€/mois</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formRent">
              <Form.Label column xs="4">
                Autres
              </Form.Label>
              <Col xs="8">
                <InputGroup>
                  <FormField
                    placeholder="ex: 50"
                    type="number"
                    min="0"
                    step="0.01"
                    name="charges.other"
                    onBlur={this._handleChange}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>€/mois</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formRent">
              <Form.Label column xs="4">
                Total mensuel
              </Form.Label>
              <Col xs="8">
                <FormField
                  plaintext
                  value={`${chargeTotal} € / mois`}
                  readOnly
                />
              </Col>
            </Form.Group>
          </FormCard>

          <FormCard names={["colocs"]}>
            <h2>
              Cohabitants <Emoji symbol="👩‍👦‍👦" />
            </h2>
            <p>Qui sont tes colocs ? Ajoute un coloc par ligne.</p>

            {colocs.map((coloc, index) => (
              <Form.Group
                key={`coloc-name-${index}`}
                as={Row}
                controlId="formRent"
              >
                <Form.Label column xs="4">
                  {index
                    ? `${index + 1}e colocataire`
                    : "Toi (1er colocataire)"}
                </Form.Label>
                <Col xs="8">
                  <InputGroup>
                    <FormField
                      placeholder={index === 0 ? "Ton nom" : ""}
                      name={`colocs[${index}].name`}
                      onBlur={this._handleChange}
                    />
                    {colocs.length > 1 && (
                      <InputGroup.Append>
                        <Button
                          variant="outline-danger"
                          onClick={this._removeColoc(index)}
                        >
                          X
                        </Button>
                      </InputGroup.Append>
                    )}
                  </InputGroup>
                </Col>
              </Form.Group>
            ))}
            <Form.Group as={Row} controlId="formRent">
              <Col sm="12" style={{ textAlign: "center" }}>
                <Button onClick={this._addColoc}>Ajouter</Button>
              </Col>
            </Form.Group>
          </FormCard>

          <FormCard names={["colocs"]}>
            <h2>
              Répartition loyer <Emoji symbol="📊" />
            </h2>
            <p>Comment vous répartissez-vous le loyer ?</p>
            <Row>
              <Col xs="4" />
              <Col xs="4">Répartition</Col>
              <Col xs="2">Loyer</Col>
              <Col xs="2">Total</Col>
            </Row>
            {colocs.map((coloc, index) => (
              <Form.Group
                key={`coloc-mult-${index}`}
                as={Row}
                controlId="formRent"
              >
                <Form.Label column xs="4">
                  {coloc.name || "Inconnu"}
                </Form.Label>
                <Col xs="4">
                  <InputGroup>
                    <FormField
                      step="0.1"
                      type="number"
                      min="0"
                      name={`colocs[${index}].multiple`}
                      onBlur={this._handleChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>{`/${multipleTotal}`}</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
                <Col xs="2">
                  <FormField
                    plaintext
                    readOnly
                    value={`${roundPrice(
                      rent / (multipleTotal / (coloc.multiple || 1)) || 0
                    )}€`}
                  />
                </Col>
                <Col xs="2">
                  <FormField
                    plaintext
                    readOnly
                    value={`${roundPrice(
                      rent / (multipleTotal / (coloc.multiple || 1)) +
                        chargeTotal / colocs.length || 0
                    )}€`}
                  />
                </Col>
              </Form.Group>
            ))}

            <Form.Group as={Row} controlId="formRent">
              <Form.Label column xs="5">
                Total
              </Form.Label>
              <Col xs="3">
                <InputGroup>
                  <FormField readOnly plaintext value={multipleTotal} />
                </InputGroup>
              </Col>
              <Col xs="2">
                <FormField
                  readOnly
                  plaintext
                  value={`${roundPrice(rent || 0)}€`}
                />
              </Col>
              <Col xs="2">
                <FormField
                  readOnly
                  plaintext
                  value={`${roundPrice(rent + chargeTotal || 0)}€`}
                />
              </Col>
            </Form.Group>
          </FormCard>

          <FormCard names={["masterAccount", "dayReminder"]}>
            <h2>
              Collecte du loyer <Emoji symbol="🧺" />
            </h2>
            <p>Comment sont collectés les loyers de chacun ?</p>
            <Form.Group as={Row} controlId="formRent">
              <Form.Label column xs="4">
                Loyés payés à
              </Form.Label>
              <Col xs="8">
                <InputGroup>
                  <Form.Control
                    as="select"
                    name="masterIndex"
                    onBlur={this._handleChange}
                  >
                    {colocs.map(({ name }, index) => (
                      <option key={`coloc-master-${index}`} value={index}>
                        {name}
                      </option>
                    ))}
                  </Form.Control>
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formRent">
              <Form.Label column xs="4">
                Sur le compte bancaire:
              </Form.Label>
              <Col xs="8">
                <InputGroup>
                  <FormField
                    name="masterAccount"
                    placeholder={"BE01 2345 6789 1234"}
                  />
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formRent">
              <Form.Label column xs="4">
                Dernier jour du mois pour la collecte
              </Form.Label>
              <Col xs="8">
                <InputGroup>
                  <FormField
                    type="number"
                    min="1"
                    max="31"
                    placeholder="ex: 25"
                    name="dayReminder"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>jour</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Group>
          </FormCard>

          <FormCard style={{ textAlign: "center", border: "0px" }}>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting && <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />}
              
              Créer la coloc !
            </Button>
          </FormCard>
        </FormCards>
      </>
    );
  }
}

export default withFirebase(CreateView);
