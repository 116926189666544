import React, {useState} from "react";
import { Form, Button, InputGroup, Modal, Alert } from "react-bootstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LinkContainer } from "react-router-bootstrap";

const URL_BASE = "https://colocbot.techally.be";

export default ({ link }) => {
    function shareLink() {
      window.open(
        "fb-messenger://share?link=" + encodeURIComponent(URL_BASE + link)
      );
    }

    const [copied, setCopied] = useState(false);
  
    return (
      <Modal show={!!link} onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>C'est presque pret !</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Parfait, maintenant il faut que tout le monde rejoigne la coloc.
          </p>
          <p>
            Partage le lien à tes colocs:
            <InputGroup>
              <Form.Control value={URL_BASE + link} />
              <InputGroup.Append>
                <CopyToClipboard text={URL_BASE + link} onCopy={() => setCopied(true)}>
                  <Button variant="outline-primary">Copier</Button>
                </CopyToClipboard>
              </InputGroup.Append>
            </InputGroup>
            Ils doivent juste cliquer sur un lien Messenegr et ils recevront les
            rappels.
          </p>

          {copied && <Alert variant="success">Le lien est copié, plus qu'à le partager!</Alert>}

          <p>
            De ton coté, tu recevras un rappel à la date que tu as demandée pour
            vérifier les paiements de chacun. Tu pourras alors lancer les
            rappels automatique à ceux qui n'ont pas payé.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <LinkContainer to={link || "/join"}>
            <Button variant="secondary">Rejoindre</Button>
          </LinkContainer>
          {window.innerWidth <= 800 && window.innerHeight <= 600 && (
            <Button variant="primary" onClick={shareLink}>
              Partager sur Messenger
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };