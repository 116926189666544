import React from "react";

import { Row, Col, Button } from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";

import "./HomeView.css";

export default () => (
    <>
      <Row className="hero align-items-center">
        <Col>
          <Row>
            <Col>
              <h2>Tes colocs oublient de payer le loyer?</h2>
              <h3>Izzie va leur rappeler!</h3>
            </Col>
          </Row>
          <Row>
            <Col className="hero-cta">
              <LinkContainer to={"/create"}>
                <Button className="btn-lg hero-button" color="primary">
                  Crée ta coloc
                </Button>
              </LinkContainer>
              <small>
              C'est gratuit
              </small>
            </Col>
          </Row>
        </Col>
        <Col className="d-none d-sm-block col-img">
          <img src={require("./profile.png")} className="img-fluid" alt="Trop de rappels, ça n'avance pas"></img>
        </Col>
      </Row>

      <Row className="how-it-works">
        <Col>
          <h3>Comment ça marche ?</h3>

          <Row className="align-items-center">
            <Col sm="6" className="order-sm-1">
              <img
                src={require("./undraw_chatting_2yvo.svg")}
                className="img-fluid"
                alt="Un message par mois"
              ></img>
            </Col>

            <Col sm="6" className="order-sm-12">
              <p>
                Chaque mois, Izzie va t'envoyer un message pour vérifier les
                paiements de chacun. Tu dois simplement vérifier que les
                montants sont corrects et valider.
              </p>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col sm="6" className="order-sm-12">
              <img
                src={require("./undraw_to_do_xvvc.svg")}
                className="img-fluid"
                alt="Tout le monde est rappelé individuellement"
              ></img>
            </Col>
            <Col sm="6" className="order-sm-1">
              <p>
                Chaque colocataire qui n'a pas payé à temps recevra un message de Izzie
                pour lui rappeler de payer.
              </p>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col sm="6" className="order-sm-1">
              <img
                src={require("./undraw_having_fun_iais.svg")}
                className="img-fluid"
                alt="C'est bon, on se relaxe"
              ></img>
            </Col>

            <Col sm="6" className="order-sm-12">
              <p>
                Quand tout le monde a confirmé, tu reçois un message
                t'indiquant que tu peux payer le loyer total au
                propriétaire.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bottom-cta">
        <Col>
          <LinkContainer to={"/create"}>
            <Button className="btn-lg hero-button" color="primary">
              Crée ta coloc
            </Button>
          </LinkContainer>
          <small>C'est gratuit</small>
        </Col>
      </Row>
    </>
  );
