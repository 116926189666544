import React, { Component } from "react";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import moment from "moment";
import queryString from "query-string";

import { withFirebase } from "../../services/Firebase";

import "./PaymentsView.css";

class PaymentsView extends Component {
  state = {
    coloc: {},
    renters: [],
    month: "2019-10",
    loading: false
  };

  rentersOnly = [];

  async componentDidMount() {
    const {
      firebase: { auth, fct },
      location: { search },
      match: { params }
    } = this.props;

    this.setState({ loading: true });

    const psid = queryString.parse(search).psid;

    if (psid) {
      const loginRenter = fct.httpsCallable("loginRenter");
      try {
        const token = await loginRenter({ psid, coloc: params.colocId });
        await auth.signInWithCustomToken(token.data);
      } catch (e) {
        console.warn(e);
        return this.setState({ error: e.message, loading: false });
      }
    }

    this._getColoc().catch(({ message }) =>
      this.setState({ error: message, loading: false })
    );
  }

  _toggle = index => () => {
    const { paid } = this.state;
    paid[index] = !paid[index];
    return this.setState({ success: false, error: false, paid });
  };

  _storePayments = async () => {
    const {
      firebase: { db }
    } = this.props;
    const { renters, month, paid, coloc } = this.state;

    try {
      const dateReminder = moment(month, "YYYY-MM")
        .date(coloc.dayReminder || 1)
        .subtract(coloc.dayReminder > coloc.dayRent ? 1 : 0, "months");

      await Promise.all(
        renters.map(renter =>
          db
            .collection("payment")
            .doc(`${renter.id}_${month}`)
            .set(
              {
                name: `${coloc.name}: Loyer ${moment(month, "YYYY-MM").format(
                  "MMMM"
                )}`,
                from: renter.id,
                to: coloc.master,
                toAccount: coloc.account,
                before: dateReminder.toDate(),
                amount: renter.amount,
                group: `${coloc.id}_${month}`,
                paid: paid[renter.id]
              },
              { merge: true }
            )
        )
      );

      this.setState({ success: true, error: false });
    } catch (e) {
      this.setState({ success: false, error: true }); 
    }
    //     const sendReminders = fct.httpsCallable("sendReminders");
    //      await sendReminders();
  };

  _checkPaid = async () => {
    const {
      firebase: { db }
    } = this.props;
    const { month, coloc } = this.state;
    this.setState({ loading: true });

    const multipleTotal = this.rentersOnly.reduce(
      (a, v) => a + parseInt(v.multiple, 10),
      0
    );

    const paid = {};
    const renters = await Promise.all(
      this.rentersOnly.map(async (renter, index) => {
        const payment = await db
          .collection("payment")
          .doc(`${renter.id}_${month}`)
          .get();
        const paymentData = payment.exists ? payment.data() : null;
        paid[renter.id] = paymentData ? paymentData.paid : false;

        const amount =
          coloc.rent / (multipleTotal / renter.multiple) +
          (coloc.chargeTotal || 0) / this.rentersOnly.length;

        return {
          ...renter,
          payment: paymentData,
          amount
        };
      })
    );

    this.setState({
      renters,
      paid,
      loading: false
    });
  };

  _changeMonth = inc => async () => {
    const { month } = this.state;
    const month2 = inc ? moment(month).add(inc, "month") : moment();
    await this.setState({  success: false, error: false, month: month2.format("YYYY-MM") });
    this._checkPaid();
  };

  _getColoc = async () => {
    const {
      firebase: { db },
      match: { params }
    } = this.props;

    this.setState({ loading: true });

    const coloc = await db
      .collection("coloc")
      .doc(params.colocId)
      .get();
    this.rentersOnly = [];

    await db
      .collection("renter")
      .where("coloc", "==", coloc.id)
      .get()
      .then(snap =>
        snap.forEach(async renter => {
          this.rentersOnly.push({
            ...renter.data(),
            id: renter.id
          });
        })
      );
    const colocData = coloc.data();

    const month = moment()
      .add(colocData.dayReminder > colocData.dayRent ? 1 : 0, "month")
      .format("YYYY-MM");

    await this.setState({
      coloc: {
        id: coloc.id,
        ...colocData
      },
      month
    });

    this._checkPaid();

    return false;
  };

  render() {
    const { coloc, renters, month, paid, loading, success, error } = this.state;

    if (loading) {
      return (
        <div className="loading">
          <Row className="align-items-center">
            <Col>
              <div className="spinner-grow" role="status" />
              <div>Chargement des paiements de la coloc...</div>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div className="payments">
        <Row>
          <Col style={{ textAlign: "center" }}>
            <h2>{`Paiements pour ${coloc.name}`}</h2>

            <div
              className="btn-group monthBox"
              role="group"
              aria-label="Basic example"
            >
              <Button
                variant="outline-primary"
                size="sm"
                onClick={this._changeMonth(-1)}
              >
                {" "}
                {"<"}{" "}
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={this._changeMonth(0)}
              >
                {moment(month, "YYY-MM").format("MMMM")}
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={this._changeMonth(1)}
              >
                {">"}
              </Button>
            </div>

            <p className="explanation">
              Vérifie les paiements de chacun, et coche chaque paiement fait.
              Chacun recevra un rappel tant qu'ils n'ont pas payé. Tu recevras
              un message quand tout le monde aura payé sa part.
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs="5"></Col>
          <Col xs="3">Montant</Col>
          <Col xs="4">Payé ?</Col>
        </Row>
        {renters.map((renter, index) => (
          <Row key={renter.id}>
            <Col xs="5">{renter.name}</Col>
            <Col xs="3">{`${Math.round(renter.amount*100)/100}€`}</Col>

            <Col xs="4">
              <Form.Check
                type="checkbox"
                onChange={this._toggle(renter.id)}
                label={paid[renter.id] ? "Payé" : "Pas payé"}
                checked={paid[renter.id]}
              />
            </Col>
          </Row>
        ))}

        <Row>
          <Col className="buttonSend">
            {!!success && (<Alert variant="success">
              Modifications enregistrées !
            </Alert>)}
            {!!error && (<Alert variant="danger">
              Une erreur s'est produite. Réessaye plus tard.
            </Alert>)}
            <Button onClick={this._storePayments}>Enregistrer</Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withFirebase(PaymentsView);
